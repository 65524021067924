import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import DryDetail from './components/Main/Servis/ServisDetail/DryDetail/DryDetail';
import RentCar from './components/Main/Servis/ServisDetail/RentCar/RentCar';
import PlitkaDetail from './components/Main/Servis/ServisDetail/PlitkaDetail/PlitkaDetail';
import PriceList from './components/Main/PriceList/PriceList';
import Kanalizaciya from './components/Main/Servis/ServisDetail/Kanalizaciya/Kanalizaciya';

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/price' element={<PriceList />} />
          <Route path='/ukladka-trotuarnoy-plitki' element={<PlitkaDetail />} />
          <Route path='/dostavka-sup-materialov' element={<DryDetail />} />
          <Route path='/arenda-spetstekhniki' element={<RentCar />} />
          <Route path='/kanalizaciya' element={<Kanalizaciya />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
