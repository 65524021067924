import React from 'react';
import './Servis.scss'


import IMG1 from '../../../img/servis/1.JPG'
import IMG2 from '../../../img/servis/2.JPG'
import IMG3 from '../../../img/servis/3.JPG'
import IMG4 from '../../../img/servis/4.JPG'


 
const Servis = () => {
    return(
        <div className="work">
                <div className="work__header-block header-block">
                    <div className="header-block__title">Наши услуги</div>
                    <div className="header-block__subtitle">Кратчайшие сроки. Качество. Гарантия.</div>
                </div>
                <div className="work__items">
                    <div className="work__column">
                        <div className="work__item">
                            <div className="work__icon">
                                <img src={IMG1} alt="" className="work__icon" />
                            </div>
                            <div className="work__title">
                                <div className="work__text">Укладка тротуарной плитки</div>
                                <div className="work__subtext">
                                    <ul>
                                        <li>- Укладка тротуарной плитки</li>
                                        <li>- Установка борта</li>
                                        <li>- Монтаж дренажной системы</li>
                                        <li>- Устройство дренажных колодцев</li>
                                        <li>- Трамбовка оснований</li>
                                    </ul>
                                </div>
                            </div>
                            <a href="/ukladka-trotuarnoy-plitki" className="mainblock__btn btn">Подробнее</a>
                        </div>
                        <div className="work__item">
                        <div className="work__icon">
                                <img src={IMG2} alt="" className="work__icon" />
                            </div>
                            <div className="work__title">
                                <div className="work__text">Доставка сыпучих материалов</div>
                                <div className="work__subtext">
                                    <ul >
                                        <li>- Гравий</li>
                                        <li>- Песок</li>
                                        <li>- Щебень</li>
                                        <li>- Чернозем</li>
                                    </ul>
                                    <ul>
                                        <li>- Галька</li>
                                        <li>- Фрезерат</li>
                                        <li>- Насыпной грунт</li>
                                    </ul>
                                </div>
                            </div>
                            <a href="/dostavka-sup-materialov" className="mainblock__btn btn">Подробнее</a>
                        </div>
                        <div className="work__item">
                        <div className="work__icon">
                                <img src={IMG3} alt="" className="work__icon" />
                            </div>
                            <div className="work__title">
                                <div className="work__text">Аренда спецтехники</div>
                                <div className="work__subtext">
                                    <ul>
                                        <li>- Мини-погрузчик</li>
                                        <li>- Экскаватор</li>
                                        <li>- Самосвал</li>
                                        <li>- Глубинная трамбовка</li>
                                        <li>- Гидроманипулятор</li>
                                        <li>- Мини-экскаватор</li>
                                    </ul>
                                </div>
                            </div>
                            <a href="/arenda-spetstekhniki" className="mainblock__btn btn">Подробнее</a>
                        </div>
                        <div className="work__item">
                        <div className="work__icon">
                                <img src={IMG4} alt="" className="work__icon" />
                            </div>
                            <div className="work__title">
                                <div className="work__text">Устройство наружной канализации под ключ за 1 день</div>
                                <div className="work__subtext">
                                    <ul>
                                        <li>- Продажа колец (ЖБИ)</li>
                                        <li>- Услуги манипулятора (доставка)</li>
                                        <li>- Услуги грейферного ковша</li>
                                        <li>- Услуги монтажников</li>
                                        <li>- Монтаж на месте</li>
                                    </ul>
                                </div>
                            </div>
                            <a href="/kanalizaciya" className="mainblock__btn btn">Подробнее</a>
                        </div>
                    </div>
                </div>
        </div>
    )
}  

export default Servis;
