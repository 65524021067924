import React from 'react'

import LOGO from '../../img/header/logo.png'

import './Footer.scss'

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__wrapper">


                    <div className="footer__contacts">
                        <div className="footer__contacts__title">
                            <p>Контакты</p>
                        </div>
                        <div className="footer__contacts__text">
                            <p className="footer__contacts__text"><span className="material-icons contact">book</span>УНП 291612552</p>
                            <p className="footer__contacts__text"><span className="material-icons contact">home</span>г. Брест, ул. Советская, 85</p>
                            <p className="footer__contacts__text"><span className="material-icons phone">call</span><a href="tel:+375298000406" className="footer__contacts__phone">+375 (29) 800-04-06</a></p>
                            <p className="footer__contacts__text"><span className="material-icons contact" >alternate_email</span>vipstroi.by@gmail.com</p>
                        </div>
                    </div>



                    <form className="footer__form">
                            <div className="footer__form-header">
                                <div className="footer__form-header__title">Остались вопросы?</div>
                                <div className="footer__form-header__text">Заполните форму</div>
                            </div>
                            <div className="footer__form-row">
                                <input type="text" placeholder="Имя" name="name" required />
                                <input type="text" name="your-tel"  placeholder="№ телефона" data-mask="+375 (__) ___-__-__"/>
                                <input type="submit" value="Перезвоните мне!" className="footer__btn btn" />
                            </div>
                        </form>



                        <div className="footer__logo">
                        <img src={LOGO} alt="Благоустройство территории" className="footer__logo__img" />
                        <div className="footer__logo__title">
                            <p className="footer__logo__title__text">2020</p>
                            <p className="footer__logo__title__subtext">Все права защищены</p>
                        </div>
                    </div>

                    </div>
            </div>
        </div>
    )
}

export default Footer