import React from 'react'
import './Modal.css'

export default class Modal extends React.Component {
    state = {
        isOpne: false
    }

    
    render() {
        return (
            <React.Fragment>
                <button className="mainblock__btn btn" onClick={()=> this.setState({isOpne: true})}>Оставить заявку</button>
                {this.state.isOpne && (
                    <div className="modal">
                        <div className="modal-body">
                        <span className="close" title="Закрыть окно" onClick={()=> this.setState({isOpne: false})}>×</span>
                        <form className="contacts__form">
                            <div className="contacts__form-header">
                                <div className="contacts__form-header-title">Остались вопросы?</div>
                                <div className="contacts__form-header-text">Заполните форму</div>
                            </div>
                            <div className="contacts__form-row">
                                <input type="text" placeholder="Имя" name="name" required />
                                <input type="text" name="your-tel"  placeholder="№ телефона" data-mask="+375 (__) ___-__-__" />
                                <input type="submit" value="Перезвоните мне!" className="mainblock__btn btn" />
                            </div>
                        </form>
                        </div>
                    </div>
                )}
                
            </React.Fragment> 
        )
    }
} 