import React from 'react'
import RentPhoto from '../../../../../img/servis/rent-car/rent-photo.jpg'
import './DryDetail.scss'
import Modal from '../../../../Modal/Modal'

function DryDetail() {
    return (
        <div className="dry-detail">
            <div className="container">
                <div className="dry-detail__wrapper">
                    <div className="work__header-block header-block">
                        <div className="header-block__title">Доставка сыпучих материалов</div>
                    </div>
                    <div className="dry-detail__header">
                        <div className="dry-detail__header__title">
                            <div className="dry-detail__header__title__text">
                                <p>Доставка от 1 - 50 т.</p>
                            </div>
                            <div className="dry-detail__header__title__subtext">
                                <p>Цена с учетом доставки:</p>
                            </div>
                        </div>
                    </div>

                    <div className="dry-detail__table">
                        <div className="soTest">
                            <div className="soflex1">
                                <div className="soflex1__a">
                                    <div className="soflex1__title">Наименование</div>
                                    <div>Гравий обычный(ПГС) 10т. / 20т.</div>
                                    <div>Гравий сеяный 10т. / 20т.</div>
                                    <div>Песок речной 10т. / 20т.</div>
                                    <div>Щебень (любая фракция) 10т. / 20т.</div>
                                    <div>Галька (один просев) 10т. / 20т.</div>
                                    <div>Галька мытая 10т. / 20т.</div>
                                    <div>Чернозем 10т. / 20т.</div>
                                    <div>Полевая земля 10т. / 20т.</div>
                                </div>
                                <div  className="soflex1__b">
                                    <div className="soflex1__title" >Цена в бел. р.</div>
                                    <div>от 170 р / от 280 р</div>
                                    <div>от 200 р / от 320 р</div>
                                    <div>от 140 р / от 160 р</div>
                                    <div>от 360 р / от 650 р</div>
                                    <div>от 320 р / от 280 р</div>
                                    <div>от 350 р / от 280 р</div>
                                    <div>от 240 р / от 350 р</div>
                                    <div>от 200 р / от 280 р</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dry-detail__footer">
                        <div className="dry-detail__footer__top">
                            <div className="dry-detail__footer__top__left">
                                <img src={RentPhoto} alt="" className="dry-detail__footer__top__left" />
                            </div>
                            <div className="dry-detail__footer__top__right">
                                <p>Доставка от 1 - 10 т.</p>
                                <p>Цена договорная</p>
                            </div>
                        </div>
                        <div className="dry-detail__footer__bottom">
                            <Modal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DryDetail 