import React from 'react';
import './AboutUs.scss'
  
const AboutUs = () => {
    return( 
        <div className="about">
            <div className="container">
                <div className="work__header-block header-block">
                    <div className="header-block__title">О нас</div>
                    <div className="header-block__subtitle">Пару слов о нас</div>
                </div>
                <div className="about__header">
                <span className="about__header__title">Мы занимаемся благоустройством территории любой сложности по Бресту и Брестской области</span>
                <span className="about__header__subtitle">Наша МИССИЯ - качественный результат работы, который Вам понравится. Именно поэтому все, что выполняем, мы делаем как для себя!</span>
            </div>
            <div className="about__body">
                <span className="about__body__title">Почему выбирают именно нас</span>
                <div className="about__row">
                    <div className="about__block">
                    <div className="about__img"><span className="material-icons md-48">thumb_up</span></div>
                        <div><span className="about__text">Большой опыт работы</span></div>
                    </div>
                    <div className="about__block">
                    <div className="about__img"><span className="material-icons md-48">done</span></div>
                        <div><span className="about__text">Гарантия и качество</span></div>
                    </div>
                    <div className="about__block">
                        <div className="about__img"><span className="material-icons md-48">schedule</span></div>
                        <div><span className="about__text">Ответственность</span></div>
                    </div>
                    <div className="about__block">
                    <div className="about__img"><span className="material-icons md-48">people</span></div>
                        <div><span className="about__text">Грамотные сотрудники</span></div>
                    </div>
                    <div className="about__block">
                    <div className="about__img"><span className="material-icons md-48">call_made</span></div>
                        <div><span className="about__text">Индивидуальный подход</span></div>
                    </div>
                    <div className="about__block">
                    <div className="about__img"><span className="material-icons md-48">account_balance_wallet</span></div>
                        <div><span className="about__text">Лучшая цена</span></div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
   
export default AboutUs;