import React from 'react'



import './PriceList.scss'

function PriceList() {
    return (
        <div className="price-list">
            <div className="price-list__container">
                <div className="work__header-block header-block">
                    <div className="header-block__title">Прайс-лист</div>
                    <div className="header-block__subtitle">Посмотрите на наш прайс-лист для некоторых видов работ</div>
                </div>

                <div className="price-list__wrapper">
                    <div className="price-list__table">
                        <div className="price-list__table__title">Укладка тротуарной плитки</div>
                        <div className="price-list__table__subtitle">
                    <div className="soTest">
                        <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Наименование</div>
                                <div>Укладка тротуарной плитки</div>
                                <div>Установка тротуарного борта</div>
                                <div>Бетонировка (борт)</div>
                                <div>Резка плитки</div>
                                <div>Установка дорожного борта</div>
                                <div>Устройство крыльца (ступени из плитки)</div>
                                <div>Устройство дренажной системы</div>
                                <div>Установка дождеприёмников</div>
                                <div>Установка дренажных колодцев</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел. р.</div>
                                <div>от 13 р/м2</div>
                                <div>от 4 р/мп</div>
                                <div>от 4 р/мп</div>
                                <div>от 4 р/мп</div>
                                <div>от 10 р/мп</div>
                                <div>от 15 р/мп</div>
                                <div>от 5 р/мп</div>
                                <div>от 15 р/шт</div>
                                <div>от 60 р/шт</div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                    <div className="price-list__table">
                        <div className="price-list__table__title">Доставка сыпучих материалов</div>
                        <div className="price-list__table__subtitle">
                            <div className="soTest">
                                <div className="soflex1">
                                    <div className="soflex1__a">
                                        <div className="soflex1__title">Наименование</div>
                                        <div>Гравий обычный(ПГС) 10т. / 20т.</div>
                                        <div>Гравий сеяный 10т. / 20т.</div>
                                        <div>Песок речной 10т. / 20т.</div>
                                        <div>Щебень (любая фракция) 10т. / 20т.</div>
                                        <div>Галька (один просев) 10т. / 20т.</div>
                                        <div>Галька мытая 10т. / 20т.</div>
                                        <div>Чернозем 10т. / 20т.</div>
                                        <div>Полевая земля 10т. / 20т.</div>
                                        <div>Вывоз грунта 10т/ 20т</div>
                                        <div>Вывоз строительного мусора 10т/20т</div>
                                        <div>Грунт на подсыпку 10т/20т</div>
                                    </div>
                                    <div  className="soflex1__b">
                                        <div className="soflex1__title" >Цена в бел. р.</div>
                                        <div>от 170р / от 280р</div>
                                        <div>от 200р / от 320р</div>
                                        <div>от 140р / от 160р</div>
                                        <div>от 360р / от 650р</div>
                                        <div>от 320р / от 280р</div>
                                        <div>от 350р / от 280р</div>
                                        <div>от 240р / от 350р</div>
                                        <div>от 200р / от 280р</div>
                                        <div>от 60р / от 100р</div>
                                        <div>от 90р / от 150р</div>
                                        <div>от 50р / от 70р</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-list__table">
                        <div className="price-list__table__title">Аренда спецтехники</div>
                        <div className="price-list__table__subtitle">
                            <div className="soTest">
                                <div className="soflex1">
                                    <div className="soflex1__a">
                                        <div className="soflex1__title">Наименование</div>
                                        <div>Экскаватор-погрузчик (JCB)</div>
                                        <div>Самосвал</div>
                                        <div>Мини-погрузчик (bobcat)</div>
                                        <div>Мини-экскаватор</div>
                                        <div>Гидроманипулятор</div>
                                        <div>Виброплита Wacker</div>
                                    </div>
                                    <div  className="soflex1__b">
                                        <div className="soflex1__title" >Цена в бел. р.</div>
                                        <div>от 50 р/ч</div>
                                        <div>от 50 р/ч</div>
                                        <div>от 50 р/ч</div>
                                        <div>от 45 р/ч</div>
                                        <div>Договорная</div>
                                        <div>Договорная</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-list__table">
                        <div className="price-list__table__title">Трамбовка</div>
                        <div className="price-list__table__subtitle">
                            <div className="soTest">
                                <div className="soflex1">
                                <div className="soflex1__a">
                                    <div className="soflex1__title">Наименование</div>
                                    <div>Виброплита</div>
                                    <div>Глубинная трамбовка</div>
                                </div>
                                    <div className="soflex1__b">
                                        <div className="soflex1__title" >Цена в бел. р.</div>
                                        <div>от 0.2 р/м2</div>
                                        <div>от 0.5 р/м2</div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceList