import React from 'react'
import IMG1 from '../../../../../img/servis/rent-car/1.JPG'
import IMG2 from '../../../../../img/servis/rent-car/2.PNG'
import IMG3 from '../../../../../img/servis/rent-car/3.JPG'
import IMG4 from '../../../../../img/servis/rent-car/4.JPG'
import IMG5 from '../../../../../img/servis/rent-car/5.PNG'
import IMG6 from '../../../../../img/servis/rent-car/6.JPG'
import './RentCar.scss'
 
function RentCar() {
    return (
        <div className="rent-car">
            <div className="container">
                <div className="rent-car__wrapper">
                    <div className="work__header-block header-block">
                        <div className="header-block__title">Аренда спецтехники</div>
                    </div>
                    <div className="rent-car__header">
                    </div>
                    <div className="rent-car__body">
                <span className="rent-car__body__title">Каталог</span>
                <div className="rent-car__row">
                    <div className="rent-car__block">
                    <div className="rent-car__img">
                        <img src={IMG1} alt="" className="rent-car__img" />
                    </div>
                        <div className="rent-car__text">
                            <span className="rent-car__text__title">Экскаватор-погрузчик (JCB)</span>
                            <span className="rent-car__text__subtitle">Стоимость аренды составляет  от 50 р/ч</span>
                        </div>
                    </div>
                    <div className="rent-car__block">
                    <div className="rent-car__img">
                        <img src={IMG2} alt="" className="rent-car__img" />
                    </div>
                    <div className="rent-car__text">
                            <span className="rent-car__text__title">Самосвал</span>
                            <span className="rent-car__text__subtitle">Стоимость аренды составляет  от 50 р/ч</span>
                        </div>
                    </div>
                    <div className="rent-car__block">
                    <div className="rent-car__img">
                        <img src={IMG3} alt="" className="rent-car__img" />
                    </div>
                    <div className="rent-car__text">
                            <span className="rent-car__text__title">Мини-погрузчик (bobcat)</span>
                            <span className="rent-car__text__subtitle">Стоимость аренды составляет  от 50 р/ч</span>
                        </div>
                    </div>
                    <div className="rent-car__block">
                        <div className="rent-car__img">
                        <img src={IMG4} alt="" className="rent-car__img" />
                        </div>
                        <div className="rent-car__text">
                            <span className="rent-car__text__title">Мини-экскаватор</span>
                            <span className="rent-car__text__subtitle">Стоимость аренды составляет  от 45 р/ч</span>
                        </div>
                    </div>
                    <div className="rent-car__block">
                    <div className="rent-car__img">
                        <img src={IMG5} alt="" className="rent-car__img" />
                    </div>
                    <div className="rent-car__text">
                            <span className="rent-car__text__title">Гидроманипулятор</span>
                            <span className="rent-car__text__subtitle">Стоимость аренды договорная</span>
                        </div>
                    </div>
                    <div className="rent-car__block">
                    <div className="rent-car__img">
                        <img src={IMG6} alt="" className="rent-car__img" />
                    </div>
                    <div className="rent-car__text">
                            <span className="rent-car__text__title">Виброплита Wacker</span>
                            <span className="rent-car__text__subtitle">
                                <p>Вес 1185 кг.</p>
                                <p>Стоимость услуги договорная</p>
                                <p>Дилерские скидки</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
        </div>
    )
}

export default RentCar