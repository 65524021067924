import React from 'react';
import './Process.scss'

import IMG1 from '../../../img/process/1.webp'
import IMG2 from '../../../img/process/2.webp'
import IMG3 from '../../../img/process/3.webp'

import IMG4 from '../../../img/process/4.webp'
import IMG5 from '../../../img/process/5.webp'
import IMG6 from '../../../img/process/6.webp'

import IMG7 from '../../../img/process/7.webp'
import IMG8 from '../../../img/process/8.webp'
import IMG9 from '../../../img/process/9.webp'

import IMG10 from '../../../img/process/10.webp'
import IMG11 from '../../../img/process/11.webp'
import IMG12 from '../../../img/process/12.webp'

import IMG13 from '../../../img/process/13.webp'
import IMG14 from '../../../img/process/14.webp'
import IMG15 from '../../../img/process/15.webp'

import IMG16 from '../../../img/process/16.webp'
import IMG17 from '../../../img/process/17.webp'
import IMG18 from '../../../img/process/18.webp'


const Process = () => {
  return (
    <div className="process">
      <div className="container">
        <div className="work__header-block header-block">
          <div className="header-block__title">Фото наших работ</div>
          <div className="header-block__subtitle">Посмотрите некоторые наши работы</div>
        </div>
        <div className="process__row">

          <div className="process__photo">
            <img src={IMG1} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG2} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG3} alt="" loading='lazy' className="process__photo" />
          </div>

          <div className="process__photo">
            <img src={IMG4} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG5} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG6} alt="" loading='lazy' className="process__photo" />
          </div>

          <div className="process__photo">
            <img src={IMG7} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG8} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG9} alt="" loading='lazy' className="process__photo" />
          </div>

          <div className="process__photo">
            <img src={IMG10} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG11} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG12} alt="" loading='lazy' className="process__photo" />
          </div>

          <div className="process__photo">
            <img src={IMG13} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG14} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG15} alt="" loading='lazy' className="process__photo" />
          </div>

          <div className="process__photo">
            <img src={IMG16} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG17} alt="" loading='lazy' className="process__photo" />
          </div>
          <div className="process__photo">
            <img src={IMG18} alt="" loading='lazy' className="process__photo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Process; 