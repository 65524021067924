import React from 'react';
import Modal from '../../Modal/Modal';
import './Promo.scss'

const Promo = () => {
    return(
        <div className="wrapper">
            <div className="mainblock">
                <div className="mainblock__content">
                    <div className="container">
                        <div className="mainblock__text">
                            <div className="mainblock__title">Благоустройство территории под ключ</div>
                            <div className="mainblock__subtitle">Индивидуальный подход к каждому клиенту</div>
                            <div className="mainblock__phone"><a href="tel:+375298000406" className="mainblock__phone__call"> +375 (29) 800-04-06 (МТС)</a></div>
                        </div>
                        <div className="mainblock__footer">
                        <Modal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
   
export default Promo;  