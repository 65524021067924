import React from 'react'
import HEADER from '../../../../../img/servis/kanalizaciya/header.JPG'
import IMG1 from '../../../../../img/servis/kanalizaciya/1.jpg'
import IMG2 from '../../../../../img/servis/kanalizaciya/2.jpg'
import IMG3 from '../../../../../img/servis/kanalizaciya/3.jpg'
import IMG4 from '../../../../../img/servis/kanalizaciya/4.jpg'
import IMG5 from '../../../../../img/servis/kanalizaciya/5.jpg'
import IMG6 from '../../../../../img/servis/kanalizaciya/6.jpg'
import IMG7 from '../../../../../img/servis/kanalizaciya/7.jpg'
import IMG8 from '../../../../../img/servis/kanalizaciya/8.jpg'
import IMG9 from '../../../../../img/servis/kanalizaciya/9.JPG'

import T1 from '../../../../../img/servis/kanalizaciya/t1.JPG'
import T2 from '../../../../../img/servis/kanalizaciya/t2.JPG'
import T3 from '../../../../../img/servis/kanalizaciya/t3.PNG'
import T4 from '../../../../../img/servis/kanalizaciya/t4.PNG'
import T5 from '../../../../../img/servis/kanalizaciya/t5.JPG'



import './Kanalizaciya.scss'
 
function Kanalizaciya() {
    return (

        <div className="kanalizaciya">
            <div className="container">
                <div className="work__header-block header-block">
                    <div className="header-block__title">Наружная канализация</div>
                </div>

                <div className="kanalizaciya__header">
                    <div className="kanalizaciya__header__left">
                        <div className="kanalizaciya__header__left__title">Продажа, доставка и монтаж наружной канализации</div>
                        <div className="kanalizaciya__header__left__subtitle">Канализации под ключ за 1 день!</div>
                    </div>
                    <div className="kanalizaciya__header__right">
                        <img src={HEADER} alt="" className="kanalizaciya__header__right" />
                    </div>
                </div>


                <div className="kanalizaciya__block">
                    <div className="kanalizaciya__block__header">
                        <img src={T1} alt="Бетонное кольцо" className="kanalizaciya__block__header__image"  />
                        <div className="kanalizaciya__block__header__title">
                            <div className="kanalizaciya__block__header__title__text">Бетонные кольца для водоснабжения и канализации</div>
                            <div className="kanalizaciya__block__header__title__subtext">- ГОСТ 8020-90.</div>
                        </div>
                    </div>

                    <div className="kanalizaciya__block__table">
                        <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Наименование</div>
                                <div>Бетонное кольцо КС 7-3</div>
                                <div>Бетонное кольцо КС 7-6</div>
                                <div>Бетонное кольцо КС 10-9</div>
                                <div>Бетонное кольцо КС 15-9</div>
                                <div>Бетонное кольцо КС 20-9</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел.р.</div>
                                <div>35 р</div>
                                <div>60 р</div>
                                <div>65 р</div>
                                <div>95 р</div>
                                <div>160 р</div>
                            </div>
                        </div>
                        </div>
                </div>


                <div className="kanalizaciya__block">
                    <div className="kanalizaciya__block__header">
                        <img src={T2} alt="Бетонное кольцо" className="kanalizaciya__block__header__image"  />
                        <div className="kanalizaciya__block__header__title">
                            <div className="kanalizaciya__block__header__title__text">Плиты днищ для колодцев из ЖБИ</div>
                            <div className="kanalizaciya__block__header__title__subtext">- ГОСТ 8020-90.</div>
                        </div>
                    </div>

                    <div className="kanalizaciya__block__table">
                        <div className="soflex1">
                                <div className="soflex1__a">
                                    <div className="soflex1__title">Наименование</div>
                                    <div>Плиты днищ ПН-10</div>
                                    <div>Плиты днищ ПН-15</div>
                                    <div>Плиты днищ ПН-20</div>
                                </div>
                                <div  className="soflex1__b">
                                    <div className="soflex1__title" >Цена в бел.р.</div>
                                    <div>35 р</div>
                                    <div>70 р</div>
                                    <div>155 р</div>
                                </div>
                            </div>
                        </div>
                </div>


                
                <div className="kanalizaciya__block">
                    <div className="kanalizaciya__block__header">
                        <img src={T3} alt="Бетонное кольцо" className="kanalizaciya__block__header__image"  />
                        <div className="kanalizaciya__block__header__title">
                            <div className="kanalizaciya__block__header__title__text">Плиты колодцев из ЖБИ</div>
                            <div className="kanalizaciya__block__header__title__subtext">- ГОСТ 8020-90.</div>
                        </div>
                    </div>

                    <div className="kanalizaciya__block__table">
                    <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Наименование</div>
                                <div>Плиты колодцев ПП-10</div>
                                <div>Плиты колодцев ПП-15</div>
                                <div>Плиты колодцев ПП-20</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел.р.</div>
                                <div>35 р</div>
                                <div>70 р</div>
                                <div>155 р</div>
                            </div>
                        </div>
                        </div>
                </div>

                <div className="kanalizaciya__block">
                    <div className="kanalizaciya__block__header">
                        <img src={T4} alt="Бетонное кольцо" className="kanalizaciya__block__header__image"  />
                        <div className="kanalizaciya__block__header__title">
                            <div className="kanalizaciya__block__header__title__text">Кольцо опорное бетонное</div>
                            <div className="kanalizaciya__block__header__title__subtext">- ГОСТ 8020-90.</div>
                        </div>
                    </div>

                    <div className="kanalizaciya__block__table">
                    <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Наименование</div>
                                <div>Кольцо опорное КО-6</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел.р.</div>
                                <div>20 р</div>
                            </div>
                        </div>
                        </div>
                </div>


                <div className="kanalizaciya__block">
                    <div className="kanalizaciya__block__header">
                        <img src={T5} alt="Бетонное кольцо" className="kanalizaciya__block__header__image"  />
                        <div className="kanalizaciya__block__header__title">
                            <div className="kanalizaciya__block__header__title__text">Прайс цен на работы</div>
                        </div>
                    </div>

                    <div className="kanalizaciya__block__table">
                    <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Услуги</div>
                                <div>Манипулятор (доставка)</div>
                                <div>Грейферный ковш</div>
                                <div>Монтаж манипулятором</div>
                                <div>Бетонировка (борт)</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел. р.</div>
                                <div>от 60 р</div>
                                <div>от 40 р.ч.</div>
                                <div>от 40 р.ч.</div>
                                <div>от 200 р</div>
                            </div>
                        </div>
                        </div>
                </div>




            

                <div className="work__header-block header-block">
                    <div className="header-block__title">Фото наших работ</div>
                    <div className="header-block__subtitle">Посмотрите некоторые наши работы</div>
                </div>
                <div className="process__row">
                    <div className="process__photo">
                        <img src={IMG1} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG2} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG3} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG4} alt="" className="process__photo" />
                    </div>

                    <div className="process__photo">
                        <img src={IMG5} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG6} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG7} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG8} alt="" className="process__photo" />
                    </div>
                    <div className="process__photo">
                        <img src={IMG9} alt="" className="process__photo" />
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Kanalizaciya
