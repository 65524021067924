import React from 'react'

import './PlitkaDetail.scss'
import IMG1 from '../../../../../img/servis/plitka-detail/1.JPG'
import IMG2 from '../../../../../img/servis/plitka-detail/2.JPG'
import Modal from '../../../../Modal/Modal'
 
function PlitkaDetail() {
    return (

        <div className="servis-detail">
            <div className="container">

                <div className="work__header-block header-block">
                    <div className="header-block__title">Укладка тротуарной плитки</div>
                </div>

                <div className="servis-detail__title">
                    <div className="">
                        <p>Укладка тротуарной плитки – одно из важных направлений нашей деятельности.</p> 
                        <p>Мы гарантируем высокое качество, оперативность и приемлемую стоимость наших работ.</p> 
                        <p>Постоянным нашим клиентам мы предлагаем дилерские скидки.</p>
                    </div>
                </div>

                <div className="servis-detail__modal">
                    <Modal  />
                </div>

                <div className="servis-detail__table">
                    <div className="soTest">
                        <div className="soflex1">
                            <div className="soflex1__a">
                                <div className="soflex1__title">Наименование</div>
                                <div>Укладка тротуарной плитки</div>
                                <div>Установка тротуарного борта</div>
                                <div>Бетонировка (борт)</div>
                                <div>Резка плитки</div>
                                <div>Установка дорожного борта</div>
                                <div>Устройство крыльца (ступени из плитки)</div>
                                <div>Устройство дренажной системы</div>
                                <div>Установка дождеприёмников</div>
                                <div>Установка дренажных колодцев</div>
                            </div>
                            <div  className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел. р.</div>
                                <div>от 13 р/м2</div>
                                <div>от 4 р/мп</div>
                                <div>от 4 р/мп</div>
                                <div>от 4 р/мп</div>
                                <div>от 10 р/мп</div>
                                <div>от 15 р/мп</div>
                                <div>от 5 р/мп</div>
                                <div>от 15 р/шт</div>
                                <div>от 60 р/шт</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="work__header-block header-block">
                    <div className="header-block__title">Трамбовка</div>
                </div>


                <div className="servis-detail__block">
                    <div className="servis-detail__block__left">
                        <div className="servis-detail__block__left__top">
                            <img src={IMG1} alt="Виброплита"  className="servis-detail__block__left__photo" />
                            <p className="servis-detail__block__left__text">Виброплита</p>
                        </div>
                        <div className="servis-detail__block__left__bottom">
                            <img src={IMG2} alt="Глубинная трамбовка"  className="servis-detail__block__left__photo" />
                            <p className="servis-detail__block__left__text">Глубинная трамбовка</p>
                        </div>
                    </div>

                    <div className="servis-detail__block__right">
                        <div className="servis-detail__block__right__header">
                            Трамбовка оснований:
                        </div>
                        <div className="servis-detail__block__right__text">
                            <p>- под фундамент</p>
                            <p>- под стяжку</p>
                            <p>- под тротуарную плитку</p>
                        </div>
                        <div className="">
                            <Modal />
                        </div>
                    </div>
                </div>

                <div className="servis-detail__table">
                    <div className="soTest">
                        <div className="soflex1">
                        <div className="soflex1__a">
                            <div className="soflex1__title">Наименование</div>
                            <div>Виброплита</div>
                            <div>Глубинная трамбовка</div>
                        </div>
                            <div className="soflex1__b">
                                <div className="soflex1__title" >Цена в бел. р.</div>
                                <div>от 0.2 р/м2</div>
                                <div>от 0.5 р/м2</div>
                            </div>
                        </div>
                    </div>
                </div> 

            </div>
        </div>
    )
}
 
export default PlitkaDetail
