import React from 'react';
import './Main.scss'
import Promo from './Promo/Promo'
import Servis from './Servis/Servis'
import AboutUs from './AboutUs/AboutUs';
import Process from './Process/Process';

const Main = () => {
    return(
        <div className="main">
            <Promo />
            <Servis />
            <AboutUs />
            <Process />
        </div>
    )
}

export default Main;